<template>

    <div class="tech mx-1 p-0">
        <div class="row g-0">
            <div class="col-4 mt-2 ml-2">
                <img :src="'/TechImg/'+ type +'.png'" alt="...">
            </div>
             <p class="card-text p-0">{{ type }}</p>
        </div>
    </div>

</template>

<script>
export default {
    name: "Tech",
    props: {
        type: {
            type: String,
            required: true,
        },
    },
}

</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.tech {
    position: relative;
    align-items: center;
    max-width: 270px;
    height: 50px;
    border-radius: 10px;
    background: #EAEAEA;
    animation: flipInX;
    animation-duration: 1.5s;
}
.tech:hover {
    cursor: default;
    transition: all 1s;
    transform: scale(1.05);
}

.card-text {
    display: flex;
    align-items: center;
    position: absolute;
    width: auto;
    height: 16px;
    left: 64px;
    top: 16px;
    color: #000000;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

</style>
