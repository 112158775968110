<template>
    <div>
        <Quote/>

        <!--   free course     -->
        <div class="Tech pb-5">
            <div class="row mb-2 col-11 px-3 px-sm-2 px-lg-5">
                <div class="fade-in">
                    <h1>Bepul o’rganing: Junior Full Stack Web Developer</h1>
                    <p>Video darslarimiz orqali dasturlashni mustaqil, bepul o’rganishingiz mumkin.</p>
                </div>
                <div class="row justify-content-center p-0">
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="U.D.T"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="PHP"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="JavaScript"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="HTML"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="CSS"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Bootstrap"/>
                </div>
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                        <router-link :to="'/course-free'" @click.native="scrollToTop">batafsil &#129122;</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end fixed-bottom mb-5 me-5 pe-3 course-reg-button">
            <router-link
                class="btn btn-secondary p-2 col-7 col-sm-4 col-md-3 col-lg-2"
                id="goToPurchase"
                to="/course#payment"
            >
                Kursga yoziling
            </router-link>
        </div>

        <div class="row zoomIn d-lg-flex justify-content-center align-items-center py-5" style="width: 100%">
            <div class="col-lg-3 col-md-3 col-sm-11 d-flex justify-content-center mx-0 pe-0">
                <ImgTag class="d-flex justify-content-center align-items-center" image="q-sign"/>
            </div>
            <div class="col-10 col-lg-5 col-md-6 col-sm-11 pt-4">
                <h1 class="course-title mb-4">
                    Pullik va bepul kurslarning nima farqi bor?
                </h1>
                <p class="course-subtitle">
                    Bepul kurslarda sizga asos (poydevor) bilimlar beriladi. Pullik kursda esa har bir mavzu alohida va
                    nozik jihatlarigacha yoritib beriladi. Va albatta, pullik kurslarda sifatga e'tibor bir necha marta
                    yuqori bo'ladi. Bundan tashqari, pullik kurslarda siz nafaqat yangi bilimlar olasiz, balki bevosita
                    Ustozingizning bir necha yillik tajribalaridan ham bahramand bo'lasiz. Tajriba esa bebaho
                    hisoblanadi va unga faqat yillar davomida erishiladi. Eng asosiysi, sizda pullik kursda Ustozingiz
                    bilan muloqot qilish imkoni bo'ladi. Ya'ni, tushunmagan joylaringizni so'rab olishingiz mumkin.
                    Bepul kurslarda bunaqa imkoniyat umuman yo'q.
                </p>
            </div>
        </div>

        <!--    junior course    -->
        <div class="Tech pb-5" style="height: auto">
            <div class="row mb-2 col-11 px-3 px-sm-2 px-lg-5">
                <div class="fade-in">
                    <h1>Pullik o’rganing: Junior Full Stack Web Developer</h1>
                    <p>Kursda 0 dan boshlab professional dasturchi bo’lish
                        uchun barcha kerakli bilimlar beriladi.</p>
                </div>
                <div class="row justify-content-center p-0">
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="U.D.T"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="PHP"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="JavaScript"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="HTML"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="CSS"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Bootstrap"/>
                </div>
                <div class="row justify-content-center p-0">
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="MySQL"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Symfony"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Api-Platform"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Vue.js"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Git"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Linux CLI"/>
                </div>
                <div class="row justify-content-center p-0">
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Design Patterns"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="phpStorm"/>
                    <div class="col-0 col-sm-6 col-md-4 col-lg-3 col-xl-8 mx-lg-2 mx-xl-0"/>
                </div>
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                        <router-link :to="'/course'" @click.native="scrollToTop">batafsil &#129122;</router-link>
                    </div>
                </div>
            </div>
        </div>

        <!--   level of developers     -->
        <div class="row zoomIn d-lg-flex justify-content-center align-items-center py-5" style="width: 100%">
            <div class="col-lg-3 col-md-4 col-sm-11 d-flex justify-content-center  mx-0 pe-0">
                <ImgTag class="d-flex justify-content-center align-items-center" image="graduation-cap"/>
            </div>
            <div class="col-10 col-lg-5 col-md-6 col-sm-11 pt-4">
                <h1 class="course-title mb-4">Qanday dasturchi darajalari mavjud?</h1>
                <p class="course-subtitle">
                    Endi dasturlashni o’rgangan odam junior dasturchi bo’ladi. Bu “kichik” dasturchi degani. Uning
                    tajribasi yo’q, yoki juda kam. <br/><br/>2-3 yil tajribaga ega bo’lgan dastuchi esa - middle,
                    ya’ni ”o’rta” dasturchi bo’ladi. <br/><br/>Agar 5-6 yilcha tajriba oshirsangiz, senior-dasturchi
                    bo’lasiz. Bu “katta” dasturchi degani.<br/><br/>Yillar bu shartli birlik. Aslida hamma narsa
                    dasturchini tajribasi va bilimiga bog’liq. Bir joyda 10 yil ishlab hech qanday yangi
                    texnologiyalarni o’rganmaslik mumkin va 10 yilda ham junior dasturchi bo’lib qolish
                    mumkin.<br/><br/>1-2 yil ichida esa senior-dasturchiga kerakli bilimlarni o’rganib olish mumkin.
                    Qiyin loyihalarda qatnashib katta tajribaga ega bo’lish mumkin.
                </p>
            </div>
        </div>

        <!--    middle course    -->
        <div class="Tech pb-5" style="height: auto">
            <div class="row mb-2 col-11 px-3 px-sm-2 px-lg-5">
                <div class="fade-in">
                    <h1>Akmal Kadirov’da malaka oshirish: Middle Full Stack Web Developer</h1>
                    <p>Buning uchun Junior Full Stack Web Developer <br>kursini tugatgan bo’lishingiz kerak</p>
                </div>
                <div class="row justify-content-center p-0">
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Docker"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="VDS"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="SSH"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Linux OS"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Apt-Get"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Cron"/>
                </div>
                <div class="row justify-content-center p-0">
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Gitlab CI_DI"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Design Patterns"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="SOLID"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Redis"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Rabbit MQ"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Jobs & Workers"/>
                </div>
                <div class="row justify-content-center p-0">
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Regular Expression"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="HTTP Client"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Parsing"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="PHP Traits"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Symfony Advanced"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Vue Advanced"/>
                </div>
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                        <router-link :to="'/course-middle'" @click.native="scrollToTop">batafsil &#129122;</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <h1 class="studentsPractices d-flex justify-content-center align-items-center m-0">
                Kadirov.dev o'quv kursining amaliyotida bajarilgan loyihalar
            </h1>
        </div>
        <div class="container mt-5">
            <b-carousel
                class="border border-dark indicator"
                v-model="slide"
                :interval="4000"
                controls
                fade
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
            >
                <!-- Text slides with image -->
                <b-carousel-slide img-src="/ImagesOfProjects/boshqar1.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="/ImagesOfProjects/boshqar2.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="/ImagesOfProjects/kadirovDev2.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="/ImagesOfProjects/itWork1.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="/ImagesOfProjects/itWork2.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="/ImagesOfProjects/testing1.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="/ImagesOfProjects/testing2.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="/ImagesOfProjects/bank2.jpg"></b-carousel-slide>

                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"/>
                </a>

                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"/>
                </a>
            </b-carousel>
        </div>
        <div ref="top" class="studentsOpinion mt-5 d-flex align-items-center">
            <p class="m-0">O'quvchilar fikri</p>
        </div>
        <div
            class="comment mt-5"
            v-for="comment in getComments"
            :key="comment.id"
        >
            <div
                class="row row_1 d-flex justify-content-center comment-row"
                v-if="comment.isApprove"
            >
                <div
                    v-if="comment.image !== null"
                    class="col col-md-4 col-xl-3 mb-3 mb-md-0 mr-md-4 text-center text-md-end align-self-center"
                >
                    <img :src="url + '/media/' + comment.image.filePath" alt="photo" class="img">
                </div>
                <div
                    v-else
                    class="col col-md-4 col-xl-3 mb-3 mb-md-0 mr-md-4 text-center text-md-end align-self-center">
                    <img src="../assets/avatar.png" alt="photo" class="img">
                </div>
                <div class="col-11 col-md-6 ml-md-4 ml-lg-0 align-self-center">
                    <div class="mx-lg-5 commentText">
                        <div class="carousel slide carousel-inner">
                            <div v-if="text !== comment.id">
                                {{ comment.text.split(' ').slice(0, 30).join(' ') }}
                                <button
                                    class="btn-comment border-0"
                                    v-if="comment.text.length > 300"
                                    @click="readMore(comment.id)"
                                >
                                    davomi...
                                </button>
                            </div>
                            <div v-if="text === comment.id">
                                {{ comment.text }}
                            </div>
                        </div>
                        <div class="d-block d-lg-flex justify-content-center justify-content-md-start">
                            <div
                                class="mt-3 font-weight-bolder align-items-start">
                            <span>
                                 {{ comment.user.givenName }} {{ comment.user.familyName }}
                            </span>
                            </div>
                            <div class="pt-lg-3">
                                 <span class="data ml-lg-2">
                                    {{ callDate(comment.createdAt) }}
                                 </span>
                            </div>
                        </div>
                        <div class="comment-like">
                            <button v-if="isAuthenticated" class="likeButton" @click="onClickLike(comment.id)">
                                <i class="bi bi-hand-thumbs-up-fill"></i>
                            </button>

                            <button v-else class="likeButton" @click="showModal">
                                <i class="bi bi-hand-thumbs-up-fill"></i>
                            </button>
                            {{ comment.likesCount }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <b-modal ref="modal" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h5>Layk bosish uchun avval tizimga kiring yoki ro'yxatdan o'ting</h5>
                </div>
                <div class="d-flex justify-content-between">
                    <b-button
                        class="mt-3 col-5"
                        variant="btn btn-primary"
                        block @click="goToLoginPage"
                    >
                        Tizimga kirish
                    </b-button>
                    <b-button
                        class="mt-3 col-5"
                        variant="btn btn-primary"
                        block @click="goToRegPage"
                    >
                        Ro'yxatdan o'tish
                    </b-button>
                </div>
            </b-modal>
        </div>

        <div class="mt-3">
            <b-pagination
                v-if="getCommentsTotalCount > 6"
                v-model="currentPage"
                pills
                align="center"
                :total-rows="getCommentsTotalCount"
                :per-page="6"
                :click-handler="fetchCommentsByPage"
                @input="fetchCommentsByPage"
            >
            </b-pagination>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import Tech from '../components/html/Tech.vue'
import Quote from "@/components/Quote";
import ImgTag from "@/components/html/ImgTag";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Home",
    components: {ImgTag, Quote, Tech},
    data() {
        return {
            url: process.env.VUE_APP_API_URL,
            currentPage: 1,
            show: true,
            slide: 0,
            sliding: null,
            result: '',
            count: 0,
            text: null,
            isLiked: {},
        }
    },
    computed: {
        ...mapGetters(['isAuthorized', 'getComments', 'getImages', 'getCommentsTotalCount', "getCommentLike"]),
        isAuthenticated() {
            return this.isAuthorized
        }
    },
    watch: {
        '$route.params.page': {
            handler: function (val) {
                this.currentPage = val ? val : 1
                this.fetchComments(this.currentPage)
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions(['fetchComments', 'mediaFetch', 'pushCommentLike']),
        onClickLike(commentId) {
            this.isLiked = !this.isLiked;
            this.pushCommentLike({ comment: '/api/comments/' + commentId })
                .then(() => {
                    this.fetchComments(this.currentPage)
                })
        },
        fetchCommentsByPage() {
            this.$router.push('/page/' + this.currentPage)
            this.$refs.top.scrollIntoView()
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        callDate(createDate) {
            let date = null

            date = createDate

            let day = date.slice(8, 10)
            let year = date.slice(0, 4)
            let month = date.slice(5, 7)

            switch (month) {
                case '01':
                    return  day + ' Yanvar ' + year + ' yil';
                case '02':
                    return  day + ' Fevral ' + year + ' yil';
                case '03':
                    return  day + ' Mart ' + year + ' yil';
                case '04':
                    return  day + ' Aprel ' + year + ' yil';
                case '05':
                    return  day + ' May ' + year + ' yil';
                case '06':
                    return  day + ' Iyun ' + year + ' yil';
                case '07':
                    return  day + ' Iyul ' + year + ' yil';
                case '08':
                    return  day + ' Avgust ' + year + ' yil';
                case '09':
                    return  day + ' Sentabr ' + year + ' yil';
                case '10':
                    return  day + ' Oktabr ' + year + ' yil';
                case '11':
                    return  day + ' Noyabr ' + year + ' yil';
                case '12':
                    return  day + ' Dekabr ' + year + ' yil';
                default:
                    return;
            }
        },
        readMore(id) {
            if (this.text === id) {
                this.text = null
            } else {
                this.text = id
            }
        },
        goToLoginPage() {
            this.$refs['modal'].hide()
            this.$router.push('/login')
        },
        goToRegPage() {
            this.$refs['modal'].hide()
            this.$router.push('/registration')
        },
        showModal() {
            this.$refs['modal'].show()
        },
    },
    mounted() {
        this.show = true
        this.mediaFetch()
        this.show = false
    }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

.fade-in {
    animation: fadeIn;
    animation-duration: 1s;
}

.zoomIn {
    animation: zoomIn;
    animation-duration: 1s;
}

.btn-secondary {
    border-radius: 10px;
    border: none;
    background-color: #027610;
    font-family: Comfortaa, cursive;
    font-size: 16px;
    width: 100%;
}

.btn-secondary:hover {
    background-color: #03ab18;
}

.img {
    height: 300px;
    width: 250px;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    border-radius: 10px;
}

.line {
    width: 400px;
    margin: auto;
}

.comment-row {
    margin-top: 111px;
}

.pagination-row {
    margin-top: 111px;
}

@media (max-width: 767.98px) {
    .comment-row {
        margin-top: 30px;
    }
}

@media (max-width: 767.98px) {
    .pagination-row {
        margin-top: 30px;
    }
}

.align-self-center {
    text-align: justify;
}

.course-title {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: justify;
}

.row {
    margin: auto;
}

.row_1 {
    margin: 20px 20px;
    padding: 15px;
    background-color: #F5F5F5;
    border-radius: 5px;
}

.course-subtitle {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: black;
    text-align: justify;
}

.feedback .section {
    margin-bottom: 100px;
}

.feedback img {
    width: 268px;
    height: auto;
    margin-left: 30%;
    margin-top: 10%;
}

.Tech {
    font-family: Comfortaa, cursive;
    font-style: normal;
    font-weight: normal;
    background: #80007F;
    padding: 30px 0;
}

.Tech h1 {
    font-size: 24px;
    line-height: 27px;
}

.Tech h1, p, a {
    color: white;
}

.Tech a {
    font-size: 18px;
    line-height: 20px;
    text-align: right;
    text-decoration-line: underline;
    padding-top: 20px;
    padding-right: 20px;
    float: right;
}

.Tech p {
    font-size: 18px;
    margin-bottom: 42px;
}

.page-link {
    color: #765AF8;;
}

nav {
    margin-bottom: 100px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    height: 50px !important;
    width: 50px !important;
    background: #000000;
    border-radius: 50px;
}

.studentsPractices {
    background: #80007F;
    color: #FFFFFF;
    font-size: 24px;
    height: auto;
    padding: 30px 48px;
}

.studentsOpinion {
    background: #80007F;
    color: #FFFFFF;
    font-size: 24px !important;
    height: 100px;
    display: flex;
    justify-content: center;
}

.course-reg-button {
    z-index: 2;
}

.data {
    font-size: 12px;
    color: grey;
}

.btn-comment{
    color: dodgerblue;
    font-size: 12px;
    background-color: inherit;
}

svg {
    margin-top: 8px;
}

.likeButton {
    border: none;
    background-color: #F7F7FC !important;
}

.likeButton:active {
    color: #B1B1B5 !important;
}
</style>
